.user-item {
  display: flex;
  padding: 0.625rem 0rem 0.625rem 0.625rem;
  justify-content: space-between;
  gap: 0.625rem;
  border-radius: 0.625rem;
  background: #132C46;

  &__elem {
    min-width: 0;
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.25rem;
    font-weight: 400;

    &_type {
      &_large {
        flex-grow: 2;
      }
    }

    &-label {
      white-space: pre;
      color: rgba(255, 255, 255, 0.50);
      font-size: 0.625rem;
    }

    &-value {
      min-width: 0;
      font-size: 0.75rem;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__arrow {
    align-self: center;
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
  }
}
