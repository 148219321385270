.total-user {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  padding: 0.625rem;
  justify-content: space-between;
  border-radius: 0.625rem;
  background: #132C46;
  cursor: pointer;

  &__item {
    min-width: 0;
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;
    font-weight: 400;

    &:first-child {
      align-items: flex-start;
    }

    &:last-child {
      align-items: flex-end;
    }

    &-label {
      white-space: pre;
      color: rgba(255, 255, 255, 0.50);
      font-size: 0.625rem;
    }

    &-value {
      min-width: 0;
      font-size: 0.75rem;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: 768px) {
    &__item {
      flex-basis: 30%;

      &:nth-child(4n) {
        align-items: flex-start;
      }

      &:nth-child(3n) {
        align-items: flex-end;
      }
    }
  }
}
