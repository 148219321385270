/* rtl:begin:ignore */
.slide-out {
  flex-grow: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &__menu {
    left: -22.5rem /* rtl:ignore */;
    position: absolute;
    width: 22.5rem;
    height: 100%;
    top: 0;
    z-index: 100;
    transition: all 0.3s ease;
    overflow: hidden;
    background: #001021;
  }

  &_open &__menu {
    left: 0;
  }

  &__menu-shadow {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: #000000B2;
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    will-change: transform;
    flex-grow: 1;
  }

  &__header {
    display: flex;
    padding: 0.9375rem 0.625rem;
    justify-content: space-between;
    align-items: center;
  }

  &__hamburger {
    display: flex;
    width: 1.875rem;
    height: 1.875rem;
    cursor: pointer;

    path {
      stroke: #E9A722;
      stroke-opacity: 1;
    }
  }

  &__logo {
    width: 9.49994rem;
    height: 1.69575rem;
    display: flex;

    &-wrapper {
      margin: 0 auto;
      display: flex;
    }
  }

  &__items {
    padding: 0.625rem 0.9375rem;
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
  }
}
/* rtl:end:ignore */
