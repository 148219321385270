.log-out {
  &__modal {
    max-width: 20.625rem;
    width: 100%;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    border-radius: 0.625rem;
    background: #0F2032;
    color: #fff;

    &-title {
      margin-bottom: 3.125rem;
      font-size: 1.125rem;
      font-weight: 600;
    }

    &-text {
      margin-bottom: 3.125rem;
      font-size: 1.375rem;
      font-weight: 400;
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    }

    &-button {
      height: 2.5rem;
    }
  }
}
