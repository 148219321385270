.transaction-history {
  flex-grow: 2;
  display: flex;
  padding: 0.9375rem 0.9375rem 1.25rem 0.9375rem;
  flex-direction: column;
  gap: 0.9375rem;

  &__title {
    padding-left: 0.75rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  &__block {
    display: flex;
    padding: 0.9375rem 1.25rem 1.25rem;
    flex-direction: column;
    gap: 0.625rem;
    border-radius: 0.625rem;
    background: #0F2032;

    &_type_big {
      flex-grow: 2;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    font-weight: 400;

    &-label {
      font-size: 0.75rem;
    }

    &-field {
      height: 2.5rem;
    }
  }

  &__checkboxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.125rem 0.625rem;

    &-title {
      font-size: 0.75rem;
      font-weight: 400;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    &-text {
      font-size: 0.875rem;
      font-weight: 600;
    }
  }

  &__paginator {
    margin-top: auto;
    align-self: center;
  }
}
