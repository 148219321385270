.user-info {
  padding: 0.625rem 0.9375rem;
  border-radius: 0.625rem;
  background: #0F2032;
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 0.625rem;
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }

  &__user {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
  }

  &__data {
    font-size: 1.125rem;
    font-weight: 400;

    &-id {
      font-size: 0.875rem;
      color: rgba(255, 255, 255, 0.50);
    }
  }

  &__balance {
    margin-bottom: 0.625rem;
    padding: 0.3125rem 0.625rem;
    border-radius: 0.625rem;
    background: #132C46;

    &-title {
      color: rgba(255, 255, 255, 0.50);
      font-size: 0.75rem;
      font-weight: 700;
    }

    &-value {
      display: flex;
      align-items: baseline;
      font-size: 1.125rem;
      font-weight: 400;
    }

    &-currency {
      color: rgba(255, 255, 255, 0.50);
      font-size: 0.875rem;
      font-weight: 700;

      &:last-child {
        margin-left: 0.3125rem;
      }
    }
  }

  &__item {
    padding: 0.625rem 0.3125rem;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    color: rgba(255, 255, 255, 0.50);
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;

    &-icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
    }
  }
}