.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__item {
    width: calc(100% - 2rem);
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1rem;
    border-bottom: none;
    background: linear-gradient(90deg, rgba(8, 24, 52, 0.4) 36.46%, rgba(27, 45, 79, 0.4) 100%), #081834;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    margin-top: .5rem;
  }
  &__image {
    width: 1.2rem;
    * {
      fill: #ffb401;
    }
  }
  &__title {
    background: #08142e;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
  }

  &__alert-message {
    width: 100%;
    margin-top: 2rem;
  }

  &__social {
    padding: 0 0.7rem;
    margin-bottom: 1.25rem;
    line-height: 1.25rem;
  }

  &__alert {
    padding: 0 0.7rem;
  }
}
