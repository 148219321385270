.side-menu {
  display: flex;
  flex-direction: column;

  &__block {
    display: flex;
    flex-direction: column;
    position: relative;

    &:not(:last-child) {
      padding-bottom: 1.25rem;

      &::after {
        content: '';
        position: absolute;
        left: 1.25rem;
        bottom: 0.625rem;
        width: 2.75rem;
        height: 0.0625rem;
        background: rgba(255, 255, 255, 0.15);
      }
    }
  }

  &__item {
    display: flex;
    padding: 0.625rem 0.75rem 0.625rem 0.625rem;
    align-items: center;
    gap: 0.625rem;
    color: rgba(255, 255, 255, 0.50);
    font-size: 1rem;
    font-weight: 400;

    &-icon {
      display: flex;
      width: 1.5rem;
      height: 1.5rem;
    }

    &-additional {
      display: flex;
      margin-left: auto;
    }
  }
}

