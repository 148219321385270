.check-box-mobile {
  user-select: none;
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  flex-shrink: 0;
  transition: all 0.3s ease-in;
  background: #96969680;
  border-radius: 0.3125rem;
  position: relative;

  &__input {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
  }

  &__check {
    width: 0.625rem;
    display: block;
  }
}
