.menu-item {
  width: 100%;
  height: 2.8rem;
  margin-bottom: 1px;
  color: #fff;
  background: linear-gradient(
      90deg,
      rgba(8, 24, 52, 0.4) 36.46%,
      rgba(27, 45, 79, 0.4) 100%
    ),
    #081834;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 1rem;
  > * {
    fill: #ffb401;
  }

  &__image {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    &-svg {
      max-height: 100%;
      max-width: 100%;
    }
  }
  &__element-list {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__element {
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1rem;
    border-bottom: none;
    background: linear-gradient(90deg, rgba(8, 24, 52, 0.4) 36.46%, rgba(27, 45, 79, 0.4) 100%), #081834;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    margin-top: .5rem;
  }
}
