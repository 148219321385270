.users-list {
  flex-grow: 2;
  display: flex;
  padding: 0.9375rem 0.9375rem 1.25rem 0.9375rem;
  flex-direction: column;
  gap: 0.625rem;

  &__title {
    padding-left: 0.75rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  &__block {
    display: flex;
    padding: 0.9375rem 1.25rem;
    flex-direction: column;
    gap: 0.3125rem;
    border-radius: 0.625rem;
    background: #0F2032;
    font-size: 0.75rem;
    font-weight: 700;

    &_type {
      &_big {
        flex-grow: 2;
        gap: 0.625rem;
        padding-bottom: 1.25rem;
      }
    }

    &-value {
      font-size: 1.125rem;
      font-weight: 600;
      display: flex;
      align-items: baseline;
    }
  }

  &__currency {
    color: rgba(255, 255, 255, 0.50);
    font-size: 0.75rem;
    font-weight: 700;

    &:last-child {
      margin-left: 0.1875rem;
    }
  }

  &__icon {
    margin-right: 0.625rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
  }

  &__search {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 0.625rem;
  }

  &__button {
    width: fit-content;
  }

  &__results {
    font-size: 0.875rem;
    font-weight: 600;
  }

  &__list {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;
  }

  &__paginator {
    margin-top: auto;
    align-self: center;
  }
}
