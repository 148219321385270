.subheader {
  background: #08142e;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  box-sizing: border-box;
  padding: 0 0.7rem;
  margin-bottom: 1px;

  &__mystery-balance {
    margin: 0 0 0 auto;
    white-space: pre-wrap;
    padding: 0.3125rem;
    border-radius: 0.3125rem;
    background: linear-gradient(135deg, #6400b3 0%, #bd00ff 100%), #fff;
    color: #fff;
    font-family: Roboto;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 100%;
  }
  &__mystery-balance-info {
    width: 2.125rem;
    display: flex;
    margin: 0 1.75rem 0 0.625rem;
    padding: 0.4375rem 0;
    justify-content: center;
    align-items: center;
    border-radius: 0.3125rem;
    background: linear-gradient(135deg, #6400b3 0%, #bd00ff 100%), #fff;
    color: #fff;
    font-family: Roboto;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 100%;
  }

  &__home {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__image {
    width: 100%;
  }
  &__info {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
  }
  &__info-content {
    width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.6875rem;
    padding: 0.9375rem 1.25rem;
    border-radius: 0.625rem;
    background-image: url(./img/back.png), linear-gradient(135deg, #6400b3 0%, #bd00ff 100%);
    background-color: #081732;
    background-position: center 0%, center;
    background-size: 85% auto, 100%;
    background-repeat: no-repeat;
  }
  &__info-cross {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__info-cross-icon {
    width: 0.75rem;
    min-width: 0.75rem;
    height: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__info-img {
    width: 14.2901rem;
    height: 7.9209rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__info-text1 {
    color: #fff;
    font-family: Roboto;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  &__info-text2 {
    color: #fff;
    font-family: Roboto Condensed;
    font-size: 0.875rem;
    font-weight: 700;
  }
  &__info-timer {
    color: #fff;
    font-family: Roboto;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}
