.header {
  flex-shrink: 0;
  height: 3.75rem;
  padding: 0rem 0.625rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  background: #001021;

  &__hamburger {
    display: flex;
    width: 1.875rem;
    height: 1.875rem;
    cursor: pointer;
  }

  &__user {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
  }

  &__data {
    margin-left: 1rem;
    font-size: 1rem;
    font-weight: 400;

    &-id {
      font-size: 0.875rem;
      color: rgba(255, 255, 255, 0.50);
    }
  }

  &__logout {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;

    &-wrapper {
      margin-left: auto;
    }
  }
}