.change-pas {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  &__button {
    margin-top: 1rem;
    width: 100%;
    height: 2.2rem;
    background: linear-gradient(90deg, #FFB401 -29.27%, #FF9901 107.32%);
    box-shadow: 0px 2px 10px rgba(253, 181, 0, 0.7);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;
  }
}