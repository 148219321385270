.user-edit {
  display: flex;
  padding: 0.9375rem 0.9375rem 1.25rem 0.9375rem;
  flex-direction: column;
  gap: 0.9375rem;

  &__title {
    padding-left: 0.75rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  &__block {
    display: flex;
    padding: 0.9375rem 1.25rem 1.25rem;
    flex-direction: column;
    gap: 1.25rem;
    border-radius: 0.625rem;
    background: #0F2032;

    &-title {
      font-size: 1.125rem;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.625rem;
  }

  &__reset-button {
    display: flex;
    height: 2.5rem;
    padding: 0 1.875rem 0 1.4375rem;
    align-items: center;
    gap: 0.3125rem;
    border-radius: 0.625rem;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background: #132C46;
    font-size: 0.875rem;
    font-weight: 700;
  }

  &__key {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    font-weight: 400;

    &-label {
      font-size: 0.75rem;
    }
  }

  &__line {
    background: rgba(255, 255, 255, 0.15);
    height: 1px;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
  }
}
