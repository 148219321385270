.password-pop-up {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 5;

  &__body {
    box-sizing: border-box;
    padding: 2.9075rem;
    background: #0F2032;
    color: #fff;
    min-width: 20.625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.1875rem;
    border-radius: 0.625rem;
  }

  &__header {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
  }

  &__password {
    font-size: 2.625rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
  }

  &__copy {
    box-sizing: border-box;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    padding: 0.5rem;
    border-radius: 0.9375rem;
    background: rgba(202, 204, 85, 0.10);
  }

  &__button {
    width: 10.75rem;
  }
}
