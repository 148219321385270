@font-face {
  font-family: 'Proxima Nova';
  src: url("shared/fonts/proxima-nova/proximanova_regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("shared/fonts/proxima-nova/proximanova_bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("shared/fonts/proxima-nova/proximanova_extrabold.otf") format("opentype");
  font-weight: 800;
}

.app {
  width: 100%;
  height: 100%;
  background: #030b1e;
  color: #fff;
  display: flex;
  flex-direction: column;

  &__content {
    flex-grow: 2;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}

#root,
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Proxima Nova', sans-serif;
  background: #000;
  overflow-x: hidden;
}

html {
  font-size: 16px;
}

ul,
ol,
li,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
}
