.paginator-mobile {
  user-select: none;
  min-width: 2.625rem;
  width: fit-content;
  display: flex;
  align-items: center;
  overflow: hidden;

  &__item {
    margin: 0 0.3125rem;
    width: 3.5em;
    height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 0.875rem;
    line-height: 1.125rem;
    background: transparent;
    // background: #132C46;
    border: 1px solid #334151;
    border-radius: 0.625rem;
  }

  &__arrow-left-container,
  &__arrow-right-container {
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    // background: #132C46;
    border-radius: 0.625rem;
    border: 1px solid #334151;
    cursor: pointer;
  }
  &__arrow-left,
  &__arrow-right {
    height: 0.75rem;
    width: 0.4375rem;
    display: flex;
  }
  &__arrow-left {
    transform: rotate(180deg);
  }
}
