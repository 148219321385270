.button {
  box-sizing: border-box;
  width: 100%;
  padding: 0.625rem;
  min-height: 2.5rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  color: #fff;
  background: #E9A722;
  border: none;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;

  &_color {
    &_transparent {
      background: transparent;
      color: #E9A722;
    }
    &_grey {
      background: rgba(255, 255, 255, 0.60);
    }
  }

  &_disabled {
    cursor: not-allowed;
    opacity: 0.5;
    box-shadow: none;
  }
}