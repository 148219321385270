.set-language {
  &__modal {
    max-width: 20.625rem;
    width: 100%;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    border-radius: 0.625rem;
    background: #0F2032;
    color: #fff;

    &-title {
      font-size: 1.125rem;
      font-weight: 600;
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 0.9375rem;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0.54881rem 9.3125rem 0.45119rem 1rem;
    gap: 0.5rem;
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 0.625rem;
    background: #132C46;
    cursor: pointer;

    &_active {
      background: #E9A722;
    }

    &-image {
      display: flex;
      width: 1.5625rem;
      height: 1.5625rem;
    }
  }

  &__base {
    padding: 0.625rem;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    font-size: 1rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.50);
    border-radius: 0.625rem;
    border: 1px solid rgba(255, 255, 255, 0.15);

    &-icon {
      display: flex;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
