.auth {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__content {
    padding: 1.25rem 1.875rem;
    display: flex;
    flex-direction: column;
  }

  &__logo {
    margin-bottom: 4.375rem;
    align-self: center;
    width: 9.49994rem;
    height: 1.69575rem;
    display: flex;
  }

  &__username {
    margin-bottom: 0.9375rem;
  }

  &__password {
    margin-bottom: 1.25rem;
  }

  &__label {
    font-size: 0.5625rem;
    font-weight: 400;

    &::after {
      content: '*';
      margin-left: 0.125rem;
      color: #D31C32;
    }
  }

  &__footer {
    margin-top: auto;
  }

  &__other {
    box-sizing: border-box;
    height: 2.5625rem;
    padding: 0.6875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.50);
    background: #0F2032;
    font-size: 1rem;
    font-weight: 700;
  }

  &__menu {
    padding: 1rem 1.191rem 1.54rem;
  }
}
