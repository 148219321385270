.create-user {
  width: 100%;
  max-width: 21.25rem;
  padding: 2.5rem 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  border-radius: 0.625rem;
  background: #0F2032;
  color: #fff;

  &__header {
    font-size: 1.125rem;
    font-weight: 600;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 1.1875rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;

    &-label {
      font-size: 0.75rem;
      font-weight: 400;
    }
  }

  &__line {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
}
