.total {
  flex-grow: 2;
  display: flex;
  padding: 0.9375rem 0.9375rem 1.25rem 0.9375rem;
  flex-direction: column;
  gap: 0.9375rem;

  &__total {
    display: flex;
    align-items: flex-end;
    gap: 1.25rem;
    padding: 0.625rem 1.25rem;

    &-icon {
      display: flex;
      width: 2.5rem;
      height: 2.5rem;
    }

    &-data {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
    }

    &-label {
      font-size: 0.75rem;
      font-weight: 600;
    }

    &-value {
      font-size: 1.25rem;
      font-weight: 800;
      line-height: 100%;

      &_type {
        &_totalDeposits {
          color: #0D9252;
        }

        &_totalWithdrawals {
          color: #D31C32;
        }
      }
    }

    &-currency {
      margin-left: auto;
      font-size: 0.75rem;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.50);
    }
  }

  &__title {
    padding-left: 0.75rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  &__block {
    display: flex;
    padding: 0.9375rem 1.25rem 1.25rem;
    flex-direction: column;
    gap: 0.625rem;
    border-radius: 0.625rem;
    background: #0F2032;

    &_type_big {
      flex-grow: 2;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    font-weight: 400;

    &-label {
      font-size: 0.75rem;
    }

    &-field {
      height: 2.5rem;
    }
  }

  &__paginator {
    margin-top: auto;
    align-self: center;
  }
}
