.change-password {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__title {
    font-size: 1.125rem;
    font-weight: 600;
  }

  &__content {
    max-width: 20.625rem;
    width: 100%;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    border-radius: 0.625rem;
    background: #0F2032;
    color: #fff;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;

    &-label {
      font-size: 0.75rem;
      font-weight: 400;
    }

    &-field {
      height: 2.5rem;
    }
  }

  &__note {
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.5);
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1.1875rem;
  }

  &__button {
    height: 2.5rem;
  }
}
