.locale {
  &__title {
    background: #08142e;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
  }
  &__item {
    width: 100%;
    height: 2.8rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 0.7rem;

    &_active {
      color: #fff;
      background: linear-gradient(
          90deg,
          rgba(8, 24, 52, 0.4) 36.46%,
          rgba(27, 45, 79, 0.4) 100%
        ),
        #081834;
    }
  }
  &__image {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__bottom {
    box-sizing: border-box;
    padding: 0.7em 1em;
  }
  &__button {
    width: 100%;
    height: 2.8rem;
    background: linear-gradient(90deg, #FFB401 -29.27%, #FF9901 107.32%);
    box-shadow: 0px 2px 10px rgba(253, 181, 0, 0.7);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;
  }
  &__ok {
    width: 1.8rem;
    position: absolute;
    right: 1rem;
  }
}
