@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(./font/password.ttf);
}

.input {
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  outline: 0;
  padding: 0;
  font-size: 0.875rem;
  border: none;
  background: transparent;
  color: #fff;
  font-family: 'Proxima Nova', sans-serif;
  $i: &;

  &::placeholder {
    color: rgba(255, 255, 255, 0.50);
  }

  // &:-webkit-autofill,
  // &:-webkit-autofill:hover,
  // &:-webkit-autofill:focus,
  // &:-webkit-autofill:active{
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: #fff;
  //   transition: background-color 5000s ease-in-out 0s;
  //   box-shadow: inset 0 0 20px 20px #132C46;
  // }

  &::-webkit-inner-spin-button {
    display: none !important;
  }

  &_activePassword {
    font-family: 'password';
  }

  &__wrapper {
    box-sizing: border-box;
    min-height: 2.5rem;
    padding: 0.375rem 1.25rem 0.375rem 0.9375rem;
    border-radius: 0.625rem;
    background: #132C46;
    display: flex;
    align-items: center;
    gap: 0.3125rem;

    &_schema {
      &_bordered {
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.15);
      }
    }

    &_withLabel {
      #{$i} {
        font-size: 0.75rem;
        min-height: 0.9375rem;
      }
    }
  }

  &__data {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    gap: 1px;
  }

  &__password {
    margin-left: auto;
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
  }

  &__measure {
    margin-left: auto;
    color: #E9A722;
    font-size: 0.875rem;
    font-weight: 400;
  }
}
