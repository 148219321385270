.notification {
  display: block;
  width: 100%;
  min-height: 4rem;
  position: relative;
  z-index: 1001;
  margin-top: 1rem;
  color: #878787;
  font-size: 0.875rem;
  font-weight: bold;
  animation: 0.7s ease init;

  &_deleting {
    animation: 0.7s ease delete;
  }

  &__img-error {
    position: absolute;
    width: 2.6rem;
    left: 0.7rem;
    top: 1.2rem;
  }

  &__img-success {
    position: absolute;
    width: 2.6rem;
    left: 0.7rem;
    top: 1.2rem;
  }

  &__img-default {
    position: absolute;
    width: 2.6rem;
    left: 0.7rem;
    top: 1.2rem;
  }

  &__left {
    height: 100%;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  &__cross {
    margin-left: auto;
    width: 1.375rem;
    height: 1.375rem;
    display: flex;
    cursor: pointer;
  }

  &__text {
    margin-right: 0.625rem;
    flex-grow: 2;
    height: 100%;
    font-weight: 600;
    font-size: 0.875rem;
    text-align: left;
    word-break: break-word;
    color: #fff;
  }

  &__wrapper {
    display: flex;
    gap: 0.625rem;
    box-sizing: border-box;
    padding: 0.8125rem;
    border: 1px solid rgba(255, 255, 255, 0.10);
    border-radius: 0.625rem;
    background: linear-gradient(89deg, rgba(255, 179, 1, 0.20) 0%, rgba(255, 179, 1, 0.00) 100%) border-box, rgba(41, 41, 41, 0.95);
    box-shadow: 0px 5px 0.625rem 0px rgba(0, 0, 0, 0.50);

    &_type_error {
      background: linear-gradient(89deg, rgba(255, 0, 70, 0.20) 0%, rgba(255, 0, 70, 0.00) 100%) border-box, rgba(41, 41, 41, 0.95);
      box-shadow: 0px 5px 0.625rem 0px rgba(0, 0, 0, 0.50);
    }

    &_type_success {
      background: linear-gradient(89deg, rgba(70, 172, 87, 0.20) 0%, rgba(70, 172, 87, 0.00) 100%) border-box, rgba(41, 41, 41, 0.95);
      box-shadow: 0px 5px 0.625rem 0px rgba(0, 0, 0, 0.50);
    }
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
  }

  @keyframes init {
    0% {
      opacity: 0;
      transform: translateX(10rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes delete {
    0% {
      opacity: 1;
    }
    70% {
      opacity: 0.5;
      transform: translateX(120%);
    }
    100% {
      opacity: 0;
      transform: translateX(200%);
      height: 0;
    }
  }
}
